import React from "react";
import resumeFile from "../documents/Sofia_Jaime_Resume.pdf";

const Resume = ({ classicHeader, darkTheme }) => {
  const educationDetails = [
    {
      yearRange: "2023 - 2028",
      title: "PhD Sociology",
      place: "University of California, Irvine",
      desc: "UC Fellow.",
    },
    {
      yearRange: "2020 - 2022",
      title: "MSc Social Sciences",
      place: "Carlos III University of Madrid",
      desc: "Juan March Servera Scholar.",
    },
    {
      yearRange: "2019 - 2020",
      title: "MA International Development Cooperation",
      place: "Comillas University of Madrid",
      desc: "Santander Fellow.",
    },
    {
      yearRange: "2018",
      title: "BA Sociology",
      place: "LMU Munich - Germany",
      desc: "Exchange Program.",
    },
    {
      yearRange: "2014 - 2019",
      title: "BA Sociology",
      place: "University of Buenos Aires",
      desc: "Honors Diploma & UBA International Fellow.",
    },
  ];

  const experienceDetails = [
    {
      yearRange: "2022 - 2023",
      title: "Scientific Researcher",
      place: "Social Data Science & AI Lab - Munich, Germany",
      desc: "Taught Fairness ML grad course; Communicated results in 3 conference of 200+ attendees; Used Facebook ads to retrieve 500+ survey participants; Organized WIDS Munich (80+ attendees).",
    },
    {
      yearRange: "2022",
      title: "Statistics Researcher",
      place: "RECSM, Pompeu Fabra University - Barcelona, Spain",
      desc: "Led statistics team; Assessed measurement quality of 30+ countries' dataset; Data management; Informed interdisciplinar & diverse stakeholders.",
    },
    {
      yearRange: "2019 - 2022",
      title: "Statistics & Social Theory Instructor",
      place: "On-site & Online University Courses",
      desc: "Taught 10+ courses to international students across multiple universities.",
    },
    {
      yearRange: "2017 - 2019",
      title: "Junior Researcher",
      place: "University of Buenos Aires - Argentina",
      desc: "Conducted 20+ in-depth interviews; Ethnographic observation; 3+ Focus groups.",
    },
  ];

  const skills = [
    {
      name: "R/RStudio; SPSS",
      percent: 85,
    },
    {
      name: "Critical Thinking",
      percent: 95,
    },
    {
      name: "MSOffice",
      percent: 100,
    },
    {
      name: "Latex/Overleaf",
      percent: 70,
    },
    {
      name: "Git/GitHub",
      percent: 90,
    },
    {
      name: "Stata",
      percent: 80,
    },
  ];

  return (
    <section
      id="resume"
      className={"section " + (darkTheme ? "bg-dark-1" : "")}
    >
      <div className={"container " + (classicHeader ? "" : "px-lg-5")}>
        {/* Heading */}
        <div className="position-relative d-flex text-center mb-5">
          <h2
            className={
              "text-24  text-uppercase fw-600 w-100 mb-0 " +
              (darkTheme ? "text-muted opacity-1" : "text-light opacity-4")
            }
          >
            Summary
          </h2>
          <p
            className={
              "text-9 text-dark fw-600 position-absolute w-100 align-self-center lh-base mb-0 " +
              (darkTheme ? "text-white" : "text-dark")
            }
          >
            {" "}
            Resume
            <span className="heading-separator-line border-bottom border-3 border-primary d-block mx-auto" />
          </p>
        </div>
        {/* Heading end*/}
        <div className="row gx-5">
          {/* My Education */}
          <div className="col-md-6">
            <h2
              className={
                "text-6 fw-600 mb-4 " + (darkTheme ? "text-white" : "")
              }
            >
              My Education
            </h2>
            {educationDetails.length > 0 &&
              educationDetails.map((value, index) => (
                <div
                  key={index}
                  className={
                    "bg-white  rounded p-4 mb-4 " +
                    (darkTheme ? "bg-dark" : "bg-white border")
                  }
                >
                  <p className="badge bg-primary text-2 fw-400">
                    {value.yearRange}
                  </p>
                  <h3 className={"text-5 " + (darkTheme ? "text-white" : "")}>
                    {value.title}
                  </h3>
                  <p className={darkTheme ? "text-primary" : "text-danger"}>
                    {value.place}
                  </p>
                  <p className={"mb-0 " + (darkTheme ? "text-white-50" : "")}>
                    {value.desc}
                  </p>
                </div>
              ))}
          </div>
          {/* My Experience */}
          <div className="col-md-6">
            <h2
              className={
                "text-6 fw-600 mb-4 " + (darkTheme ? "text-white" : "")
              }
            >
              My Experience
            </h2>
            {experienceDetails.length > 0 &&
              experienceDetails.map((value, index) => (
                <div
                  key={index}
                  className={
                    "bg-white  rounded p-4 mb-4 " +
                    (darkTheme ? "bg-dark" : "bg-white border")
                  }
                >
                  <p className="badge bg-primary text-2 fw-400">
                    {value.yearRange}
                  </p>
                  <h3 className={"text-5 " + (darkTheme ? "text-white" : "")}>
                    {value.title}
                  </h3>
                  <p className={darkTheme ? "text-primary" : "text-danger"}>
                    {value.place}
                  </p>
                  <p className={"mb-0 " + (darkTheme ? "text-white-50" : "")}>
                    {value.desc}
                  </p>
                </div>
              ))}
          </div>
        </div>
        {/* My Skills */}
        <h2
          className={
            "text-6 fw-600 mt-4 mb-4 " + (darkTheme ? "text-white" : "")
          }
        >
          My Skills
        </h2>
        <div className="row gx-5">
          {skills.length > 0 &&
            skills.map((skill, index) => (
              <div key={index} className="col-md-6">
                <p
                  className={
                    " fw-500 text-start mb-2 " +
                    (darkTheme ? "text-light" : "text-dark")
                  }
                >
                  {skill.name}{" "}
                  <span className="float-end">{skill.percent}%</span>
                </p>
                <div
                  className={
                    "progress progress-sm mb-4 " + (darkTheme ? "bg-dark" : "")
                  }
                >
                  <div
                    className="progress-bar"
                    role="progressbar"
                    style={{ width: skill.percent + "%" }}
                    aria-valuenow={skill.percent}
                    aria-valuemin={0}
                    aria-valuemax={100}
                  />
                </div>
              </div>
            ))}
        </div>
        <div className="text-center mt-5">
          <a
            className="btn btn-outline-secondary rounded-pill shadow-none"
            href={resumeFile}
            download
          >
            Download CV
            <span className="ms-1">
              <i className="fas fa-download" />
            </span>
          </a>
        </div>
      </div>
    </section>
  );
};

export default Resume;
