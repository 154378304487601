import React from "react";

const Disclaimer = ({ darkTheme }) => {
  return (
    <div
      id="disclaimer"
      className="modal fade"
      role="dialog"
      aria-hidden="true"
    >
      <div
        className="modal-dialog modal-lg modal-dialog-centered"
        role="document"
      >
        <div
          className={
            "modal-content " + (darkTheme ? "bg-dark-2 text-light" : "")
          }
        >
          <div className="modal-header">
            <h5 className={"modal-title " + (darkTheme ? "text-white" : "")}>
              Copyright &amp; Disclaimer
            </h5>
            <button
              type="button"
              className={"btn-close " + (darkTheme ? "btn-close-white" : "")}
              data-bs-dismiss="modal"
              aria-label="Close"
            />
          </div>
          <div className="modal-body p-4">
            <p>
            The information provided by Sofia Jaime ("we," "us," or "our") on www.sofiajaime.com (the "Site") is for general informational purposes only. All information on the Site is provided in good faith, however, we make no representation or warranty of any kind, express or implied, regarding the accuracy, adequacy, validity, reliability, availability, or completeness of any information on the Site.


            </p>
            <ul className="lh-lg">
              <li>
              The information contained on this Site is not intended to be a substitute for professional advice. Any reliance you place on such information is strictly at your own risk. We encourage you to consult with the appropriate professionals before taking any actions based on such information.
              </li>
              <li>
              The Site may contain (or you may be sent through the Site) links to other websites or content belonging to or originating from third parties or links to websites and features in banners or other advertising. Such external links are not investigated, monitored, or checked for accuracy, adequacy, validity, reliability, availability, or completeness by us. We do not warrant, endorse, guarantee, or assume responsibility for the accuracy or reliability of any information offered by third-party websites linked through the site or any website or feature linked in any banner or other advertising. We will not be a party to or in any way be responsible for monitoring any transaction between you and third-party providers of products or services.
              </li>
              <li>
              The mention of specific companies, organizations, or authorities on this Site does not imply endorsement by Sofia Jaime or the Site. Nor does the appearance of external links on this Site constitute an endorsement by Sofia Jaime of the linked websites, or the information, products, or services contained therein.
              </li>
              <li>
              Any views or opinions represented on this Site are personal and belong solely to Site owners and do not represent those of people, institutions, or organizations that Sofia Jaime may or may not be associated with in a professional or personal capacity unless explicitly stated.
              </li>
              <li>
              While we have made every attempt to ensure that the information contained in this Site has been obtained from reliable sources, Sofia Jaime is not responsible for any errors or omissions or for the results obtained from the use of this information.
              </li>
              <li>
              Your use of the Site and your reliance on any information on the Site is solely at your own risk.
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Disclaimer;
