import React from "react";

const TermsAndConditions = ({ darkTheme }) => {
  return (
    <div
      id="terms-policy"
      className="modal fade"
      role="dialog"
      aria-hidden="true"
    >
      <div
        className="modal-dialog modal-lg modal-dialog-centered"
        role="document"
      >
        <div
          className={
            "modal-content " + (darkTheme ? "bg-dark-2 text-light" : "")
          }
        >
          <div className="modal-header">
            <h5 className={"modal-title " + (darkTheme ? "text-white" : "")}>
              Terms &amp; Policy
            </h5>
            <button
              type="button"
              className={"btn-close " + (darkTheme ? "btn-close-white" : "")}
              data-bs-dismiss="modal"
              aria-label="Close"
            />
          </div>
          <div className="modal-body p-4">
            <p>
            Welcome to www.sofiajaime.com!

These terms and conditions outline the rules and regulations for the use of Sofia Jaime's Website, located at www.sofiajaime.com.

By accessing this website we assume you accept these terms and conditions. Do not continue to use www.sofiajaime.com if you do not agree to take all of the terms and conditions stated on this page.
            </p>
            <h3 className={"mb-3 mt-4 mt-4 " + (darkTheme ? "text-white" : "")}>
              License
            </h3>
            <p>
            Unless otherwise stated, Sofia Jaime and/or its licensors own the intellectual property rights for all material on www.sofiajaime.com. All intellectual property rights are reserved. You may access this from www.sofiajaime.com for your own personal use subjected to restrictions set in these terms and conditions.
            </p>
            <h5 className={"text-4 mt-4 " + (darkTheme ? "text-white" : "")}>
            Terms and Contions:
            </h5>
            <p>
            By accessing and using this site, you accept and agree to be bound by the following terms and conditions. Please review them carefully. If you do not agree to these terms, you should not use this website.

Content Usage: The content displayed on this website, including images, designs, and text, is owned by Sofia Jaime. You may view the content for personal, non-commercial purposes only. Any reproduction, distribution, or other use of the content without prior written consent from Sofia Jaime is strictly prohibited.

Conduct: Users are expected to use the website respectfully and refrain from infringing on the rights of others, engaging in illegal activities, or uploading harmful or malicious content.

Linking Policy: You may link to the website's content as long as the link does not portray Sofia Jaime or her services in a false, misleading, derogatory, or otherwise offensive manner.

Amendments: Sofia Jaime reserves the right to amend these terms and conditions at any time without prior notice. Your continued use of the website following any changes indicates your acceptance of the new terms.

Contact: For any inquiries or permissions related to the use of content, please contact Sofia Jaime directly through the contact information provided on the website.

By using this website, you acknowledge that you have read and understood these Terms and Conditions and agree to be bound by them.
            </p>
            {/* <h5 className={"text-4 mt-4 " + (darkTheme ? "text-white" : "")}>
              Part II – Information that Sofia processes on your behalf
            </h5>
            <p>
            Parts of this website offer an opportunity for users to post and exchange opinions and information in certain areas of the website. Sofia Jaime does not filter, edit, publish or review Comments prior to their presence on the website. Comments do not reflect the views and opinions of Sofia Jaime,its agents and/or affiliates. Comments reflect the views and opinions of the person who post their views and opinions. To the extent permitted by applicable laws, Sofia Jaime shall not be liable for the Comments or for any liability, damages or expenses caused and/or suffered as a result of any use of and/or posting of and/or appearance of the Comments on this website.
            </p> */}
            {/* <h5 className={"text-4 mt-4 " + (darkTheme ? "text-white" : "")}>
              Part III – General
            </h5>
            <p>
              It has survived not only five centuries, but also the leap into
              electronic typesetting, remaining essentially unchanged. Lorem
              Ipsum has been the industry's standard dummy text ever since the
              1500s, when an unknown printer took a galley of type and scrambled
              it to make a type specimen book.
            </p> */}
            {/* <h3 className={"mb-3 mt-4 " + (darkTheme ? "text-white" : "")}>
              Privacy Policy
            </h3>
            <p>
              Lorem Ipsum is simply dummy text of the printing and typesetting
              industry. Lorem Ipsum has been the industry's standard dummy text
              ever since the 1500s, when an unknown printer took a galley of
              type and scrambled it to make a type specimen book.
            </p> */}
            <ol className="lh-lg">
            </ol>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TermsAndConditions;
