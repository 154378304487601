import React, { useEffect, useRef, useState } from "react";
import Isotope from "isotope-layout";
import ProjectDetailsModal from "./ProjectDetailsModal";
const Portfolio = ({ classicHeader, darkTheme }) => {
  // init one ref to store the future isotope object
  const isotope = useRef();
  // store the filter keyword in a state
  const [filterKey, setFilterKey] = useState("*");
  const [imagesLoaded, setimagesLoaded] = useState(0);
  const [selectedProjectDetails, setSelectedProjectDetails] = useState();

  const filters = {
    RESEARCH: "Research",
    ADVOCACY: "Advocacy",
    COMMUNICATION: "Communication",
    BLOGPOSTS: "Blogposts",
    COURSES: "Courses",
  };

  const projectsData = [
    {
      title: "Multisite Migration Project",
      projectInfo:
        "30000+ obs, multiple datasets; Studying Latin American migrants in Europe compared to those who did not migrate; MA Thesis on Argentinian migrants in Spain & Israel.",
      client: "Carlos III University of Madrid",
      technologies: "R/RStudio; MSOffice; Stata",
      industry: "Social Science Research",
      date: "July 16, 2022",
      url: {
        name: "www.example.com",
        link: "https://www.example.com",
      // },
      // socialLinks: {
      //   facebook: "http://www.facebook.com/",
      //   twitter: "http://www.twitter.com/",
      //   google: "http://www.google.com/",
      //   instagram: "http://www.instagram.com/",
      //   mail: "mailto:example@gmail.com",
      },
      thumbImage: "images/projects/migrating.png",
      sliderImages: [
        "images/projects/bigsss1.JPG",
        "images/projects/bigsss2.JPG",
      ],
      categories: [filters.RESEARCH],
    },
    {
      title: "Argentinian Socioeconomic Index",
      projectInfo:
        "ARSEI: Developing the first-ever continuous measure of socioeconomic status of Argentina; Merging, cleaning, managing large national datasets 50000+ obs; Using Structural Equation Modeling to estimate measurement quality of ARSEI.",
      client: "Comillas University of Madrid",
      technologies: "SPSS; MSOffice",
      industry: "Social Science Research",
      date: "June 1, 2019",
      url: {
        name: "www.example.com",
        link: "https://www.example.com",
      },
      socialLinks: {
        facebook: "http://www.facebook.com/",
        twitter: "http://www.twitter.com/",
        google: "http://www.google.com/",
        instagram: "http://www.instagram.com/",
        mail: "mailto:example@gmail.com",
      },
      thumbImage: "images/projects/arsei.png",
      sliderImages: [
        "images/projects/arsei1.png",
        "images/projects/arsei2.png",
        "images/projects/arsei3.png",
      ],
      categories: [filters.RESEARCH],
    },
    {
      title: "Ethnic Classifications in Algorithmic Fairness",
      projectInfo:
        "We address the challenges and implications of ensuring fairness in algorithmic decision-making (ADM) practices related to ethnicity. Expanding beyond the U.S.-centric approach to race, we provide an overview of ethnic classification schemes in European countries and emphasize how the distinct approaches to ethnicity in Europe can impact fairness assessments in ADM. Drawing on large-scale German survey data, we highlight differences in ethnic disadvantage across subpopulations defined by different measures of ethnicity. We build prediction models in the labor market, health, and finance domain and investigate the fairness implications of different ethnic classification schemes across multiple prediction tasks and fairness metrics. Our results show considerable variation in fairness scores across ethnic classifications, where error disparities for the same model can be twice as large when using different operationalizations of ethnicity. We argue that ethnic classifications differ in their ability to identify ethnic disadvantage across ADM domains and advocate for context-sensitive operationalizations of ethnicity and its transparent reporting in fair machine learning (ML) applications.",
      client: "LMU Munich",
      technologies: "R/Rstudio",
      industry: "Applied computing; Social and behavioral science",
      date: "Under Review",
      url: {
        name: "www.ewaf.com/ethnic-adm-brief",
        link: "https://madoc.bib.uni-mannheim.de/65735/",
      },
      socialLinks: {
        facebook: "http://www.facebook.com/",
        twitter: "http://www.twitter.com/",
        google: "http://www.google.com/",
        instagram: "http://www.instagram.com/",
        mail: "mailto:example@gmail.com",
      },
      thumbImage: "images/projects/ethclass2.png",
      // sliderImages: [
      //   "images/projects/project-3.jpg",
      //   "images/projects/project-5.jpg",
      // ],
      categories: [filters.RESEARCH],
    },
    {
      title: "European Workshop on Algorithmic Fairness",
      projectInfo:
        "Communicated results to a 50+ attendees international audience; Led Q&A session.",
      client: "EWAF Switzerland",
      technologies: "PowerPoint; Overleaf",
      industry: "Research",
      date: "2023",
      url: {
        name: "www.ewaf2023.com",
        link: "https://ceur-ws.org/Vol-3442/",
      },
      socialLinks: {
        facebook: "http://www.facebook.com/",
        twitter: "http://www.twitter.com/",
        google: "http://www.google.com/",
        instagram: "http://www.instagram.com/",
        mail: "mailto:example@gmail.com",
      },
      thumbImage: "images/projects/ethclass1.png",
      sliderImages: [
        "images/projects/ethclass3.jpeg",
        "images/projects/ethclass4.jpeg",
      ],
      categories: [filters.COMMUNICATION],
    },
    {
      title: "Effects of Recruitment Images in a Facebook Survey",
      projectInfo:
        "Retrieved 300+ survey participants using Facebook Ads; Analyzed how images affected survey participation; Presented results to a 30+ individuals' audience from industry/academic.",
      client: "University of Maryland; American Association of Public Opinion Research.",
      technologies: "Social Media",
      industry: "Research & Marketing",
      date: "2023",
      url: {
        name: "www.aapor.com",
        link: "https://aapor.confex.com/aapor/2023/meetingapp.cgi/Paper/1851",
      },
      socialLinks: {
        facebook: "http://www.facebook.com/",
        twitter: "http://www.twitter.com/",
        google: "http://www.google.com/",
        instagram: "http://www.instagram.com/",
        mail: "mailto:example@gmail.com",
      },
      thumbImage: "images/projects/aapor1.jpeg",
      sliderImages: [
        "images/projects/fbk.png",
      ],
      categories: [filters.COMMUNICATION, filters.RESEARCH],
    },
    {
      title: "Book: Social Classes in Argentina",
      projectInfo:
        "Co-authored book with techinques, tools & guides to apply R/Rstudio and quantitative methods for the study of social inequality.",
      client: "EDUNTREF",
      technologies: "R/RStudio",
      industry: "Higher Education",
      date: "2023",
      url: {
        name: "github.io/clases-arg",
        link: "https://nsacco.github.io/clases-arg/",
      },
      socialLinks: {
        github: "https://nsacco.github.io/clases-arg/",
        twitter: "http://www.twitter.com/",
        google: "http://www.google.com/",
        instagram: "http://www.instagram.com/",
        mail: "mailto:example@gmail.com",
      },
      thumbImage: "images/projects/clases.png",
      sliderImages: [
        "images/projects/book1.jpeg",
        "images/projects/book2.jpeg",
        "images/projects/book3.jpeg",
        "images/projects/book4.jpeg",
      ],
      categories: [filters.RESEARCH],
    },
    {
      title: "Data Analysis & Survey Design",
      projectInfo:
        "Designed, conducted and analysed of Public Opinion surveys to 50+ National Parliament Candidates.",
      client: "Fundar & ECOFeminita",
      technologies: "MSOffice; Google Forms",
      industry: "Survey Methods",
      date: "2022",
      url: {
        name: "feminindex",
        link: "https://ecofeminita.com/paridad-y-propuestas-el-feminismo-esta-en-ambos-lados/?v=7516fd43adaa",
      },
      socialLinks: {
        facebook: "http://www.facebook.com/",
        twitter: "http://www.twitter.com/",
        google: "http://www.google.com/",
        instagram: "http://www.instagram.com/",
        mail: "mailto:example@gmail.com",
      },
      thumbImage: "images/projects/feminindex.png",
      // sliderImages: [
      //   "images/projects/project-1.jpg",
      //   "images/projects/project-5.jpg",
      // ],
      categories: [filters.ADVOCACY],
    },
    {
      title: "Scientific Activism",
      projectInfo:
        "Acting & debating in favor of scientific rights; Participating in a community of 100+ members of Argentinian researchers living abroad.",
      client: "Argentinian Education Ministry; CONICET; Public Affairs Ministry",
      technologies: "Social Media",
      industry: "Research & Politics",
      date: "Present",
      url: {
        name: "www.example.com",
        link: "https://www.example.com",
      },
      socialLinks: {
        facebook: "http://www.facebook.com/",
        twitter: "http://www.twitter.com/",
        google: "http://www.google.com/",
        instagram: "http://www.instagram.com/",
        mail: "mailto:example@gmail.com",
      },
      thumbImage: "images/projects/embassador.jpeg",
      sliderImages: [
        "images/projects/rcicae_pic1.jpg",
        "images/projects/rcicae_pic2.JPG",
        "images/projects/rcicae_pic3.jpeg",
      ],
      categories: [filters.ADVOCACY],
    },
    {
      title: "Data Analysis & Social Media Campaign",
      projectInfo:
        "Reporting on international cooperation projects in Africa; Coding of data from Spanish Parliament to include in OJITO app; Advocacy and fundraising support; Communication Strategy.",
      client: "Political Watch",
      technologies: "Social Media",
      industry: "Research & Marketing",
      date: "2020",
      url: {
        name: "www.ojito.com",
        link: "https://ojito.politicalwatch.es/#/",
      },
      socialLinks: {
        facebook: "http://www.facebook.com/",
        twitter: "http://www.twitter.com/",
        google: "http://www.google.com/",
        instagram: "http://www.instagram.com/",
        mail: "mailto:example@gmail.com",
      },
      thumbImage: "images/projects/ojito.png",
      // sliderImages: [
      //   "images/projects/project-1.jpg",
      //   "images/projects/project-5.jpg",
      // ],
      categories: [filters.ADVOCACY],
    },
    {
      title: "Fairness in Machine Learning",
      projectInfo:
        "Graduate course.",
      client: "LMU Munich",
      technologies: "R/RStudio",
      industry: "Higher Education",
      date: "2023",
      url: {
        // name: "www.example.com",
        // link: "https://www.example.com",
      },
      socialLinks: {
        facebook: "http://www.facebook.com/",
        twitter: "http://www.twitter.com/",
        google: "http://www.google.com/",
        instagram: "http://www.instagram.com/",
        mail: "mailto:example@gmail.com",
      },
      thumbImage: "images/projects/fairness.png",
      // sliderImages: [
      //   "images/projects/project-1.jpg",
      //   "images/projects/project-5.jpg",
      // ],
      categories: [filters.COMMUNICATION],
    },
    {
      title: "It's Hot, But is it Melting?",
      projectInfo:
        "Immigration remains a hot topic in global discourse, more so now in the AI-driven age. A striking result from the IOM - UN Migration 2022 Migration Report highlights the increasing diversity within the US: approximately 50.6 million residents, or 15% of the population as of 2024, originated from foreign lands. This statistic leads us to ask —are immigrants' and their descendants integrating into the American society?.",
      client: "LinkedIn",
      technologies: "Social Media",
      industry: "Research",
      date: "March 5, 2024",
      url: {
        name: "https://www.linkedin.com/pulse/its-hot-melting",
        link: "https://www.linkedin.com/pulse/its-hot-melting-immigrant-descendants-integration-ai-era-sofia-jaime-zzx8f/?trackingId=TZdl20odRmuuqjp3VLrB7A%3D%3D",
      },
      socialLinks: {
        facebook: "http://www.facebook.com/",
        twitter: "http://www.twitter.com/",
        google: "http://www.google.com/",
        instagram: "http://www.instagram.com/",
        mail: "mailto:example@gmail.com",
      },
      thumbImage: "images/projects/linkedin1.jpeg",
      sliderImages: [
        "images/projects/linkedin1.jpeg",
      ],
      categories: [filters.BLOGPOSTS],
    },
    {
      title: "The EU AI Act: A Step Towards Less Discrimination?",
      projectInfo:
        "The risks of AI have been widely documented (e.g., the COMPAS case). In an era where Artificial Intelligence (AI) permeates every aspect of our lives, the European Union (EU) has taken a pioneering step with its AI Legislation, in an effort to ensure that this technology becomes more responsible, transparent and ethical. This EU AI Act classifies AI systems into three distinct categories based on their risk level: banned, high-risk, and limited risk, similar to a set of traffic lights guiding the ethical and legal use of AI. But does this legislation foster less discrimination?",       
      client: "LinkedIn",
      technologies: "Social Media",
      industry: "Technology",
      date: "March 14, 2024",
      url: {
        name: "https://www.linkedin.com/pulse/eu-ai-act-step",
        link: "https://www.linkedin.com/pulse/eu-ai-act-step-towards-less-discrimination-sofia-jaime-nw8pf/?trackingId=43%2BvK9g7QQWZIU44IS2oyg%3D%3D",
      },
      socialLinks: {
        facebook: "http://www.facebook.com/",
        twitter: "http://www.twitter.com/",
        google: "http://www.google.com/",
        instagram: "http://www.instagram.com/",
        mail: "mailto:example@gmail.com",
      },
      thumbImage: "images/projects/linkedin2.png",
      // sliderImages: [
      //   "images/projects/project-3.jpg",
      //   "images/projects/project-5.jpg",
      // ],
      categories: [filters.BLOGPOSTS],
    },
    {
      title: "Fairness in Machine Learning",
      projectInfo:
        "Graduate course.",
      client: "LMU Munich",
      technologies: "R/RStudio",
      industry: "Higher Education",
      date: "2023",
      url: {
        // name: "www.example.com",
        // link: "https://www.example.com",
      },
      socialLinks: {
        facebook: "http://www.facebook.com/",
        twitter: "http://www.twitter.com/",
        google: "http://www.google.com/",
        instagram: "http://www.instagram.com/",
        mail: "mailto:example@gmail.com",
      },
      thumbImage: "images/projects/fairness.png",
      // sliderImages: [
      //   "images/projects/project-1.jpg",
      //   "images/projects/project-5.jpg",
      // ],
      categories: [filters.COURSES],
    },
  ];

  // initialize an Isotope object with configs
  useEffect(() => {
    isotope.current = new Isotope(".portfolio-filter", {
      itemSelector: ".filter-item",
      layoutMode: "masonry",
    });

    // cleanup
    return () => {
      isotope.current.destroy();
    };
  }, []);

  // handling filter key change
  useEffect(() => {
    if (imagesLoaded) {
      filterKey === "*"
        ? isotope.current.arrange({ filter: `*` })
        : isotope.current.arrange({ filter: `.${filterKey}` });
    }
  }, [filterKey, imagesLoaded]);

  const handleFilterKeyChange = (key) => () => setFilterKey(key);

  return (
    <>
      <section
        id="portfolio"
        className={"section " + (darkTheme ? "bg-dark-2" : "bg-light")}
      >
        <div className={"container " + (classicHeader ? "" : "px-lg-5")}>
          {/* Heading */}
          <div className="position-relative d-flex text-center mb-5">
            <h2
              className={
                "text-24  text-uppercase fw-600 w-100 mb-0 " +
                (darkTheme ? "text-muted opacity-1" : "text-light opacity-4")
              }
            >
              Portfolio
            </h2>
            <p
              className={
                "text-9 text-dark fw-600 position-absolute w-100 align-self-center lh-base mb-0 " +
                (darkTheme ? "text-white" : "text-dark")
              }
            >
              {" "}
              My Work
              <span className="heading-separator-line border-bottom border-3 border-primary d-block mx-auto" />
            </p>
          </div>
          {/* Heading end*/}
          {/* Filter Menu */}
          <ul
            className={
              "portfolio-menu nav nav-tabs justify-content-center border-bottom-0 mb-5 " +
              (darkTheme ? "nav-light" : "")
            }
          >
            <li className="nav-item">
              <button
                className={"nav-link " + (filterKey === "*" ? "active" : "")}
                onClick={handleFilterKeyChange("*")}
              >
                All
              </button>
            </li>
            {Object.keys(filters).map((oneKey, i) => (
              <li className="nav-item" key={i}>
                <button
                  className={
                    "nav-link " +
                    (filterKey === filters[oneKey] ? "active" : "")
                  }
                  onClick={handleFilterKeyChange(filters[oneKey])}
                >
                  {filters[oneKey]}
                </button>
              </li>
            ))}
          </ul>
          {/* Filter Menu end */}
          <div className="portfolio popup-ajax-gallery">
            <div className="row portfolio-filter filter-container g-4">
              {projectsData.length > 0 &&
                projectsData.map((project, index) => (
                  <div
                    className={
                      "col-sm-6 col-lg-4 filter-item " +
                      project.categories.join(" ")
                    }
                    key={index}
                  >
                    <div className="portfolio-box rounded">
                      <div className="portfolio-img rounded">
                        <img
                          onLoad={() => {
                            setimagesLoaded(imagesLoaded + 1);
                          }}
                          className="img-fluid d-block portfolio-image"
                          src={project.thumbImage}
                          alt=""
                        />
                        <div className="portfolio-overlay">
                          <a
                            className="popup-ajax stretched-link"
                            href=""
                            onClick={() => {
                              setSelectedProjectDetails(projectsData[index]);
                            }}
                            data-bs-toggle="modal"
                            data-bs-target="#exampleModal"
                          />
                          <div className="portfolio-overlay-details">
                            <h5 className="text-white fw-400">
                              {project.title}
                            </h5>
                            <span className="text-light"></span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
            </div>
          </div>
        </div>
      </section>
      <div className="project-details-modal">
        {/* Modal */}
        <ProjectDetailsModal
          projectDetails={selectedProjectDetails}
          darkTheme={darkTheme}
        ></ProjectDetailsModal>
      </div>
    </>
  );
};

export default Portfolio;